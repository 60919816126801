import {
  Button,
  Card,
  CardContent,
  CardHeader,
  FormControl,
  Grid,
  TextField,
} from "@mui/material";
import Tabla from "../../../../components/Tabla";
import { useEffect, useState } from "react";
import { obtenerTransacciones } from "../../../../services/taecel";
import CustomCircularProgress from "../../../../components/CustomCircularProgress";
import FilaTransaccion from "./FilaTransaccion";
import { obtenerUsuarios } from "../../../../services/bola";
import StatusCircle from "../../../../components/StatusCircle";
import { Moment, Money, Utils } from "../../../../utils";
import SelectControl from "../../../../components/SelectControl";
import { useDispatch, useSelector } from "react-redux";
import {
  setCargandoTransacciones,
  setCargandoUsuarios,
  setTransacciones,
  setTransaccionesFiltradas,
  setUsuarioID,
  setUsuarios,
} from "../../../../features/administracion/administracionSlice";
import { DesktopDatePicker } from "@mui/x-date-pickers";
const HEADER_DATA =
  "#.Bolsa.Logo.Proveedor.Monto.Referencia.Fecha.Status.Usuario".split(".");

export default function TablaTransacciones() {
  const { cargandoTransacciones } = useSelector(
    (state) => state.administracion
  );
  const dispatch = useDispatch();

  useEffect(() => {
    cargarDatos();
  }, []);

  const cargarDatos = async () => {
    try {
      dispatch(setCargandoTransacciones(true));
      const _transacciones = await obtenerTransacciones({
        limitarNumTrans: true,
      });
      // FILTRAR TRANSACCIONES
      let _exitosas = [];
      let _fracasadas = [];
      _transacciones.forEach((transaccion) => {
        if (transaccion.Status == "Exitosa") {
          _exitosas.push(transaccion);
        }
        if (transaccion.Status != "Exitosa") {
          _fracasadas.push(transaccion);
        }
      });
      dispatch(setTransacciones(_transacciones));
      dispatch(setTransaccionesFiltradas(_transacciones));
      dispatch(setCargandoTransacciones(false));
    } catch ({ message }) {
      alert(message);
    }
  };

  return (
    <Card>
      <CardHeader
        title="Transacciones"
        subheader={
          <div style={styles.subheader}>
            {!cargandoTransacciones && (
              <>
                <SubheaderInfo />
                <UsuarioSelect />
              </>
            )}
          </div>
        }
      />
      <CardContent>
        {cargandoTransacciones && <CustomCircularProgress />}
        {!cargandoTransacciones && <TablaContenido />}
      </CardContent>
    </Card>
  );
}

function TablaContenido() {
  const { transacciones, transaccionesFiltradas, usuarios, usuarioID } =
    useSelector((state) => state.administracion);
  const dispatch = useDispatch();

  useEffect(() => {
    // FILTRAMOS TANSACCIONES POR USUARIO
    if (usuarioID != "") {
      let _filtradas = [];
      if (usuarioID == "todo") {
        _filtradas = [...transacciones];
      } else {
        const user = usuarios.find((item) => item.id == usuarioID);
        _filtradas = [...transacciones].filter(
          (item) => item._usuario == user.usuario
        );
      }
      dispatch(setTransaccionesFiltradas(_filtradas));
    }
  }, [usuarioID]);

  return (
    <div>
      <FiltroFecha />
      <Tabla
        headData={HEADER_DATA}
        data={transaccionesFiltradas}
        renderItem={(item, index) => (
          <FilaTransaccion key={index} transaccion={item} index={index} />
        )}
      />
    </div>
  );
}

function SubheaderInfo() {
  const { transaccionesFiltradas } = useSelector(
    (state) => state.administracion
  );
  const [exitosas, setExitosas] = useState(0);
  const [totalExitosas, setTotalExitosas] = useState(0);
  const [fracasadas, setFracasadas] = useState(0);

  useEffect(() => {
    // FILTRAR TRANSACCIONES
    let _exitosas = [];
    let _fracasadas = 0;
    transaccionesFiltradas.forEach((transaccion) => {
      if (transaccion.Status == "Exitosa") {
        _exitosas.push(transaccion);
      }
      if (transaccion.Status != "Exitosa") {
        _fracasadas++;
      }
    });
    setExitosas(_exitosas.length);
    setTotalExitosas(
      _exitosas.reduce((acc, el) => {
        return acc + Utils.moneyToNumber(el.Monto);
      }, 0) // $2,200.00 -> ['$', '0']
    );
    setFracasadas(_fracasadas);
  }, [transaccionesFiltradas]);

  return (
    <Grid container spacing={1}>
      <Grid item xs={12} md={4}>
        <StatusCircle text={`Total: ${transaccionesFiltradas.length}`} />
        <StatusCircle text={`Fracasadas: ${fracasadas}`} color="red" />
        <StatusCircle
          text={`Exitosas(${exitosas})
            ${Money(totalExitosas)}`}
          color="green"
        />
      </Grid>
    </Grid>
  );
}

function UsuarioSelect() {
  const { cargandoUsuarios, usuarios, usuarioID } = useSelector(
    (state) => state.administracion
  );
  const dispatch = useDispatch();

  useEffect(() => {
    cargarUsuarios();
  }, []);

  const cargarUsuarios = async () => {
    try {
      dispatch(setCargandoUsuarios(true));
      let _usuarios = await obtenerUsuarios();
      _usuarios.unshift({ id: "todo", nomComercial: "Todo" });
      dispatch(setUsuarios(_usuarios));
      dispatch(setUsuarioID(_usuarios[0].id));
      dispatch(setCargandoUsuarios(false));
    } catch ({ message }) {
      alert(message);
    }
  };

  const handleChange = (value) => {
    dispatch(setUsuarioID(value));
  };

  return (
    <div style={{ width: 200 }}>
      <SelectControl
        label="Filtro"
        options={usuarios}
        optionLabel="nomComercial"
        optionValue="id"
        onChange={handleChange}
        disabled={cargandoUsuarios}
        value={usuarioID}
      />
    </div>
  );
}

function FiltroFecha() {
  const { transacciones, usuarioID, usuarios } = useSelector(
    (state) => state.administracion
  );
  const [canFilterAply, setCanFilterAply] = useState(true);
  const [dates, setDates] = useState({
    from: new Date(),
    to: new Date(),
  });
  const dispatch = useDispatch();

  useEffect(() => {
    setDates({
      from: new Date(),
      to: new Date(),
    });
  }, [usuarioID]);

  useEffect(() => {
    const fromD = Moment(dates.from).format("YYYY-MM-DD");
    const toD = Moment(dates.to).format("YYYY-MM-DD");
    setCanFilterAply(Moment(fromD).isSameOrBefore(Moment(toD)));
  }, [dates]);

  const handleSelectDate = (inputDateName, date) => {
    setDates((currentState) => ({
      ...currentState,
      [inputDateName]: new Date(date),
    }));
  };

  const handleBtnClick = () => {
    const fromD = Moment(dates.from).format("YYYY-MM-DD");
    const toD = Moment(dates.to).format("YYYY-MM-DD");
    if (Moment(fromD).isSameOrBefore(Moment(toD))) {
      let _filtradas = [];
      if (usuarioID == "todo") {
        _filtradas = [...transacciones].filter((item) => {
          const item_fecha = Moment(item.Fecha || item._fecha).format(
            "YYYY-MM-DD"
          );
          return (
            Moment(item_fecha).isSame(fromD) ||
            Moment(item_fecha).isSame(toD) ||
            Moment(item_fecha).isBetween(Moment(fromD), Moment(toD))
          );
        });
      } else {
        const user = usuarios.find((item) => item.id == usuarioID);
        _filtradas = [...transacciones].filter((item) => {
          const item_fecha = Moment(item.Fecha || item._fecha).format(
            "YYYY-MM-DD"
          );
          return (
            item._usuario == user.usuario &&
            (Moment(item_fecha).isSame(fromD) ||
              Moment(item_fecha).isSame(toD) ||
              Moment(item_fecha).isBetween(Moment(fromD), Moment(toD)))
          );
        });
      }
      dispatch(setTransaccionesFiltradas(_filtradas));
    }
  };

  return (
    <Grid container spacing={1} sx={{ marginBottom: 2 }}>
      <Grid item xs={12} md={4}>
        <FormControl fullWidth>
          <DesktopDatePicker
            label="Del"
            inputFormat="YYYY/MM/DD"
            value={dates.from}
            onChange={(date) => handleSelectDate("from", date)}
            renderInput={(params) => <TextField size="small" {...params} />}
          />
        </FormControl>
      </Grid>
      <Grid item xs={12} md={4}>
        <FormControl fullWidth>
          <DesktopDatePicker
            label="Al"
            inputFormat="YYYY/MM/DD"
            value={dates.to}
            onChange={(date) => handleSelectDate("to", date)}
            renderInput={(params) => <TextField size="small" {...params} />}
          />
        </FormControl>
      </Grid>
      <Grid item xs={12} md={4}>
        <FormControl fullWidth>
          <Button
            disabled={!canFilterAply}
            disableElevation
            variant="contained"
            onClick={handleBtnClick}
          >
            Aplicar filtro
          </Button>
        </FormControl>
      </Grid>
    </Grid>
  );
}

const styles = {
  subheader: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
};
