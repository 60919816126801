export const DATABASE_TABLES = {
  USERS: "usuarios",
  CANCELED_TICKETS: "boletosCancelados",
  CREDITS: "creditos",
  DEPOSITS: "depositos",
  DRAWS: "ajustes/sorteos",
  PAID_PRIZES: "premiosPagados",
  PAYOUTS: "ajustes/abonos",
  PAST_DUE_BALANCES: "saldosVencidos",
  TICKETS: "boletos",
  TRANSACTIONS: "transacciones",
  WEEK_INFORMS: "weekInforms",
  VOUCHERS: "vouchers",
};

export const DEPOSIT_DAYS = {
  OUT_OF_LIMIT: ["jueves", "viernes", "sabado", "domingo"],
  TOLERANCE_PERIOD: [],
};
