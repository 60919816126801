import { Paper, Tab, Tabs } from "@mui/material";
import { useState } from "react";
import { useCustomParams } from "../../../hooks";

export default function TabsComponent({
  tabNames,
  tabs,
  defaultTabIndex,
  right,
}) {
  const customParams = useCustomParams();
  const [tabIndex, setTabIndex] = useState(parseInt(defaultTabIndex));

  const handleChange = (event, newValue) => {
    setTabIndex(newValue);
    customParams.update("tabIndex", newValue);
  };

  return (
    <>
      <Paper
        sx={{
          borderRadius: 0,
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          paddingTop: 1,
          paddingRight: 2,
        }}
      >
        <Tabs
          value={tabIndex}
          onChange={handleChange}
          aria-label="tabs"
          variant="scrollable"
        >
          {tabNames.map((name, index) => (
            <Tab key={index} label={name} {...a11yProps(index)} />
          ))}
        </Tabs>
        {right}
      </Paper>
      {tabs.map((tab, index) => (
        <TabPanel key={index} value={tabIndex} index={index}>
          {tab}
        </TabPanel>
      ))}
    </>
  );
}

function TabPanel(props) {
  const { children, value, index } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      style={{ paddingTop: 20 }}
    >
      {value === index && <>{children}</>}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
