import { useEffect, useState } from "react";
import {
  Grid,
  Card,
  CardContent,
  CardMedia,
  Button,
  Toolbar,
  Typography,
  FormControl,
  TextField,
  FormLabel,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Alert,
  AlertTitle,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import {
  AccessibilityIcon,
  EditIcon,
  InfoIcon,
  MoneyIcon,
} from "../../../icons";
import { useNavigate, useParams } from "react-router-dom";
import { useModal, useToast, useUsuario, useUsuarios } from "../../../hooks";
import { Moment, Money } from "../../../utils";
import { useDispatch, useSelector } from "react-redux";
import {
  actualizarUsuarioEnLista,
  agregarAbonoStore,
  cambiarEstadoUsuarioStore,
  eliminarAbonoStore,
  eliminarUsuarioStore,
  setListaUsuarios,
  setSelectedUsuario,
  setToolbarOptionsState,
} from "../../../features/usuarios/usuariosSlice";
import {
  actualizarUsuario,
  cambiarEstadoUsuario,
  cambiarPermisoReimpresion,
  eliminarAbonoDB,
  eliminarUsuario,
  guardarAbono,
  obtenerBoletosReimpresion,
  obtenerDispositivosRegistrados,
  resetLoginAttempts,
} from "../../../services/bola";
import CustomModal from "../../../components/CustomModal";
import CustomList from "../../../components/CustomList";
import CustomTable from "../../../components/CustomTable";
import AdminPasswordField from "../../../components/AdminPasswordField";
import ScrollView from "../../../components/ScrollView";
import UsuarioCardLoading from "../../../placeholders/UsuarioCard";
import InfoRow from "../../../components/InfoRow";
import IconButtonWithLabel from "../../../components/IconButtonWithLabel";
import Toast from "../../../components/Toast";
import DispositivosTable from "./DispositivosTable";

export default function UsuarioInfoCard() {
  let { usuarioKey } = useParams();
  const { cargandoUsuario, usuario, obtenerUsuario } = useUsuario();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setToolbarOptionsState("informacion"));
    obtenerUsuario(usuarioKey);
  }, [usuarioKey]);

  return (
    <>
      {cargandoUsuario && <UsuarioCardLoading />}
      {!cargandoUsuario && usuario && <UsuarioCard />}
      {!cargandoUsuario && !usuario && <div>Usuario no encontrado</div>}
    </>
  );
}

const UsuarioCard = () => {
  return (
    <Card>
      <CardMedia
        sx={{
          background: "#37474f",
          minHeight: 200,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <UsuarioMediaCard />
      </CardMedia>
      <CardContent>
        <UsuarioToolbar />
        <UsuarioCardContent />
      </CardContent>
    </Card>
  );
};

const UsuarioCardContent = () => {
  const { toolbar } = useSelector((state) => state.usuarios);
  if (toolbar.informacion) return <UsuarioInfo />;
  if (toolbar.editar) return <EditarUsuarioForm />;
  if (toolbar.abonar) return <AbonarUsuario />;
  if (toolbar.estado) return <EstadoUsuario />;
  return null;
};

const UsuarioMediaCard = () => {
  const { selectedUsuario } = useSelector((state) => state.usuarios);
  const firstLetter = selectedUsuario
    ? selectedUsuario.nomComercial.split("")[0]
    : "";
  return (
    <div>
      <div
        style={{
          width: 130,
          height: 130,
          background: "#1ED760",
          borderRadius: "50%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          fontSize: "3rem",
          fontWeight: "bold",
          color: "#fff",
          margin: "0 auto",
        }}
      >
        {firstLetter}
      </div>
      <span
        style={{
          textAlign: "center",
          display: "inline-block",
          width: "100%",
          color: "white",
          fontSize: 24,
          fontWeight: "bold",
        }}
      >
        {selectedUsuario ? selectedUsuario.nomComercial : ""}
      </span>
    </div>
  );
};

const UsuarioToolbar = () => {
  const { toolbar } = useSelector((state) => state.usuarios);
  const dispatch = useDispatch();

  const handleClick = (action) => {
    dispatch(setToolbarOptionsState(action));
  };

  return (
    <>
      <Toolbar sx={{ justifyContent: "center", marginBottom: 3 }}>
        <IconButtonWithLabel
          label="Información"
          icon={
            <InfoIcon
              sx={{
                color: toolbar.informacion ? "#1ED760" : "white",
              }}
            />
          }
          onClick={() => handleClick("informacion")}
        />
        <IconButtonWithLabel
          label="Editar"
          icon={
            <EditIcon
              sx={{
                color: toolbar.editar ? "#1ED760" : "white",
              }}
            />
          }
          onClick={() => handleClick("editar")}
        />
        <IconButtonWithLabel
          label="Abonar"
          icon={
            <MoneyIcon
              sx={{
                color: toolbar.abonar ? "#1ED760" : "white",
              }}
            />
          }
          onClick={() => handleClick("abonar")}
        />
        <IconButtonWithLabel
          label="Estado"
          icon={
            <AccessibilityIcon
              sx={{
                color: toolbar.estado ? "#1ED760" : "white",
              }}
            />
          }
          onClick={() => handleClick("estado")}
        />
      </Toolbar>
    </>
  );
};

const UsuarioInfo = () => {
  const { selectedUsuario } = useSelector((state) => state.usuarios);
  return (
    <>
      <Grid container>
        <Grid item xs={12} md={6}>
          <InfoRow label="Nombre" value={selectedUsuario?.nombre} />
        </Grid>
        <Grid item xs={12} md={6}>
          <InfoRow label="Apellidos" value={selectedUsuario?.apellidos} />
        </Grid>
        <Grid item xs={12} md={6}>
          <InfoRow label="Domicilio" value={selectedUsuario?.domicilio} />
        </Grid>
        <Grid item xs={12} md={6}>
          <InfoRow
            label="Nombre Comercial"
            value={selectedUsuario?.nomComercial}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <InfoRow label="Usuario" value={selectedUsuario?.usuario} />
        </Grid>
        <Grid item xs={12} md={6}>
          <InfoRow
            label="Limite venta"
            value={Money(selectedUsuario?.limiteVenta)}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <InfoRow
            label="Credito Disponible"
            value={Money(selectedUsuario?.creditoDisponible)}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <InfoRow
            label="Dispositivos permitidos"
            value={selectedUsuario?.devicesAllowed}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <InfoRow
            label="Version app instalada"
            value={selectedUsuario.versionAppInstalada || "No verificada"}
          />
        </Grid>
        <Grid item xs={12}>
          <DispositivosTable />
        </Grid>
      </Grid>
    </>
  );
};

const EditarUsuarioForm = () => {
  const { selectedUsuario } = useSelector((state) => state.usuarios);
  const [inputs, setInputs] = useState({
    nombre: selectedUsuario?.nombre,
    apellidos: selectedUsuario?.apellidos || "",
    domicilio: selectedUsuario?.domicilio,
    nomComercial: selectedUsuario?.nomComercial,
    limiteVenta: selectedUsuario?.limiteVenta,
    creditoDisponible: parseFloat(selectedUsuario?.creditoDisponible),
    devicesAllowed: selectedUsuario?.devicesAllowed,
  });
  const [password, setPassword] = useState("");
  const { modalConfig, setModalConfig } = useModal();
  const dispatch = useDispatch();

  const handleInputChange = ({ target }) => {
    setInputs((prevState) => ({
      ...prevState,
      [target.name]: target.value,
    }));
  };

  const handlePasswordChange = ({ target }) => {
    setPassword(target.value.trim());
  };

  const onAccept = () => {
    if (!_confirmButtonDisabled()) {
      setModalConfig((prevState) => ({
        ...prevState,
        open: true,
        type: "alert",
        alertTitle: "¿Actualizar información?",
        contentType: "admin-password",
        action: "actualizar",
        showCancelBtn: true,
        confirmBtnText: "Aceptar",
      }));
    }
  };

  const handleModalAccept = async () => {
    const actions = {
      actualizar: () => actualizar(),
      cerrar: () => handleModalCancel(),
    };
    actions[modalConfig.action]();
  };

  const handleModalCancel = () => {
    setModalConfig((prevState) => ({
      ...prevState,
      open: false,
    }));
  };

  const _confirmButtonDisabled = () => {
    if (
      inputs.nombre.trim() === "" ||
      inputs.apellidos.trim() === "" ||
      inputs.domicilio.trim() === "" ||
      inputs.nomComercial.trim() === "" ||
      inputs.limiteVenta === "" ||
      inputs.limiteVenta < 0 ||
      inputs.creditoDisponible < 0 ||
      inputs.creditoDisponible > inputs.limiteVenta ||
      inputs.devicesAllowed < 1
    ) {
      return true;
    }
    return false;
  };

  const disableModalConfirmBtn = () => {
    return (
      modalConfig.contentType === "admin-password" && password.trim() === ""
    );
  };

  const actualizar = async () => {
    try {
      setModalConfig((prevState) => ({
        ...prevState,
        open: true,
        type: "progress",
        progressTitle: "Actualizando",
      }));
      setPassword("");
      const updatedUsuario = await actualizarUsuario(password, {
        ...inputs,
        usuarioKey: selectedUsuario.key,
      });
      // SI SE GUARDO
      if (updatedUsuario) {
        setModalConfig((prevState) => ({
          ...prevState,
          open: false,
        }));
        // ACTUALIZAR EN STORE
        dispatch(actualizarUsuarioEnLista(updatedUsuario));
        dispatch(setSelectedUsuario(updatedUsuario));
        dispatch(setToolbarOptionsState("informacion"));
      }
    } catch ({ message }) {
      setModalConfig((prevState) => ({
        ...prevState,
        type: "alert",
        alertTitle: "Mensaje",
        contentType: "error",
        content: <span>{message}</span>,
        action: "cerrar",
        showCancelBtn: false,
        confirmBtnText: "Entendido",
      }));
    }
  };

  const confirmButtonDisabled = _confirmButtonDisabled();

  return (
    <>
      <Grid container rowSpacing={2} columnSpacing={2}>
        <Grid item xs={12} md={6}>
          <FormControl fullWidth>
            <FormLabel>Nombre</FormLabel>
            <TextField
              onChange={handleInputChange}
              size="small"
              name="nombre"
              value={inputs.nombre}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} md={6}>
          <FormControl fullWidth>
            <FormLabel>Apellidos</FormLabel>
            <TextField
              name="apellidos"
              onChange={handleInputChange}
              size="small"
              value={inputs.apellidos}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} md={6}>
          <FormControl fullWidth>
            <FormLabel>Domicilio</FormLabel>
            <TextField
              name="domicilio"
              onChange={handleInputChange}
              size="small"
              value={inputs.domicilio}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} md={6}>
          <FormControl fullWidth>
            <FormLabel>Nombre Comercial</FormLabel>
            <TextField
              name="nomComercial"
              onChange={handleInputChange}
              size="small"
              value={inputs.nomComercial}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} md={6}>
          <FormControl fullWidth>
            <FormLabel>Usuario</FormLabel>
            <TextField disabled size="small" value={selectedUsuario.usuario} />
          </FormControl>
        </Grid>
        <Grid item xs={12} md={6}>
          <FormControl fullWidth>
            <FormLabel>Limite Venta</FormLabel>
            <TextField
              name="limiteVenta"
              onChange={handleInputChange}
              type="number"
              size="small"
              value={inputs.limiteVenta}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} md={6}>
          <FormControl fullWidth>
            <FormLabel>Credito disponible</FormLabel>
            <TextField
              name="creditoDisponible"
              onChange={handleInputChange}
              type="number"
              size="small"
              value={inputs.creditoDisponible}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} md={6}>
          <FormControl fullWidth>
            <FormLabel>Dispositivos permitidos</FormLabel>
            <TextField
              name="devicesAllowed"
              onChange={handleInputChange}
              type="number"
              size="small"
              value={inputs.devicesAllowed}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <Button
            variant="contained"
            color="primary"
            disabled={confirmButtonDisabled}
            onClick={() => onAccept()}
          >
            Aceptar
          </Button>
        </Grid>
      </Grid>
      <CustomModal
        open={modalConfig.open}
        type={modalConfig.type}
        alertTitle={modalConfig.alertTitle}
        progressTitle={modalConfig.progressTitle}
        showCancelBtn={modalConfig.showCancelBtn}
        showConfirmBtn={modalConfig.showConfirmBtn}
        cancelBtnText={modalConfig.cancelBtnText}
        confirmBtnText={modalConfig.confirmBtnText}
        disableConfirmBtn={disableModalConfirmBtn()}
        onCancel={handleModalCancel}
        onAccept={handleModalAccept}
      >
        <>
          {modalConfig.contentType === "admin-password" && (
            <AdminPasswordField
              password={password}
              message="Por seguridad, ingresa tu contraseña para poder continuar con el proceso."
              onChange={handlePasswordChange}
            />
          )}
          {modalConfig.contentType === "error" && modalConfig.content}
        </>
      </CustomModal>
    </>
  );
};

const AbonarUsuario = () => {
  const [inputs, setInputs] = useState({
    abono: 0,
    tipo: "abono", // abono | reembolso | ajuste
    fecha: new Date(),
    password: "",
  });
  const [selectedAbono, setSelectedAbono] = useState(null);
  const { selectedUsuario, listaAbonos, cargandoAbonos } = useSelector(
    (state) => state.usuarios
  );
  const { modalConfig, setModalConfig } = useModal();
  const { cargarAbonos } = useUsuarios();
  const dispatch = useDispatch();

  useEffect(() => {
    cargarAbonos(selectedUsuario.key);
  }, []);

  const handleInputChange = ({ target }) => {
    setInputs((prevState) => ({
      ...prevState,
      [target.name]: target.value,
    }));
  };

  const handleDateChange = (date) => {
    setInputs((prevState) => ({
      ...prevState,
      fecha: new Date(date),
    }));
  };

  const handleAccept = () => {
    if (!disableAcceptBtn()) {
      setModalConfig((prevState) => ({
        ...prevState,
        open: true,
        type: "alert",
        alertTitle: "¿Abonar a cuenta de usuario?",
        contentType: "admin-password",
        action: "agregarAbono",
      }));
    }
  };

  const disableAcceptBtn = () => {
    return inputs.abono == 0 || inputs.fecha === "" || inputs.abono === "";
  };

  const disableModalConfirmBtn = () => {
    const types = "admin-password.eliminar".split(".");
    return (
      types.includes(modalConfig.contentType) && inputs.password.trim() === ""
    );
  };

  const handleModalAccept = async () => {
    const actions = {
      agregarAbono: () => agregarAbono(),
      eliminar: () => eliminarAbono(),
      cerrar: () => handleModalCancel(),
    };
    actions[modalConfig.action]();
  };

  const handleModalCancel = () => {
    setModalConfig((prevState) => ({
      ...prevState,
      open: false,
    }));
    setSelectedAbono(null);
  };

  const agregarAbono = async () => {
    try {
      setModalConfig((prevState) => ({
        ...prevState,
        open: true,
        type: "progress",
        progressTitle: "Realizando abono",
      }));
      const nuevoAbono = await guardarAbono(inputs.password, {
        cantidad: inputs.abono,
        fechaAbono: Moment(inputs.fecha).format("YYYY-MM-DD"),
        agenciaId: selectedUsuario.id,
        usuarioKey: selectedUsuario.key,
        tipo: inputs.tipo,
      });
      // SI SE GUARDO CORRECTAMENTE
      if (nuevoAbono) {
        setModalConfig((prevState) => ({
          ...prevState,
          open: false,
        }));
        setInputs({
          abono: 0,
          fecha: new Date(),
          password: "",
          tipo: "abono",
        });
        dispatch(agregarAbonoStore(nuevoAbono));
      }
    } catch ({ message }) {
      setModalConfig((prevState) => ({
        ...prevState,
        type: "alert",
        alertTitle: "Mensaje",
        contentType: "error",
        content: <span>{message}</span>,
        action: "cerrar",
        showCancelBtn: false,
        confirmBtnText: "Entendido",
      }));
    }
  };

  const eliminarAbono = async () => {
    if (inputs.password.trim() !== "" && selectedAbono) {
      setModalConfig((prevState) => ({
        ...prevState,
        open: true,
        type: "progress",
        progressTitle: "Eliminando abono",
      }));
      const abonoEliminado = await eliminarAbonoDB(
        inputs.password,
        selectedAbono
      );
      if (abonoEliminado) {
        setModalConfig((prevState) => ({
          ...prevState,
          open: false,
        }));
        setInputs((prevState) => ({
          ...prevState,
          password: "",
        }));
        dispatch(eliminarAbonoStore(selectedAbono));
        setSelectedAbono(null);
      }
    }
  };

  const handleRowClick = (item) => {
    setSelectedAbono(item);
    setModalConfig((prevState) => ({
      ...prevState,
      open: true,
      type: "alert",
      alertTitle: "¿Eliminar abono?",
      contentType: "eliminar",
      action: "eliminar",
    }));
  };

  return (
    <>
      <Grid container rowSpacing={1} columnSpacing={1}>
        <Grid item xs={12} md={4}>
          <Typography sx={{ marginBottom: 3 }}>Abonar al usuario</Typography>
          <FormControl fullWidth>
            <InputLabel id="tipo-abono-select">Tipo</InputLabel>
            <Select
              size="small"
              disabled={false}
              labelId="tipo-abono-select"
              value={inputs.tipo}
              label="Tipo"
              onChange={({ target: { value } }) =>
                setInputs((currentValue) => ({ ...currentValue, tipo: value }))
              }
            >
              <MenuItem value="abono">Abono</MenuItem>
              <MenuItem value="reembolso">Reembolso</MenuItem>
              <MenuItem value="ajuste">Ajuste</MenuItem>
            </Select>
          </FormControl>
          <FormControl fullWidth sx={{ marginY: 2 }}>
            <FormLabel>Cantidad</FormLabel>
            <TextField
              onChange={handleInputChange}
              size="small"
              name="abono"
              type="number"
              value={inputs.abono}
            />
          </FormControl>
          <FormControl fullWidth style={{ marginTop: 20 }}>
            <DesktopDatePicker
              label="Fecha del abono"
              inputFormat="YYYY/MM/DD"
              value={inputs.fecha}
              onChange={handleDateChange}
              renderInput={(params) => <TextField {...params} />}
            />
          </FormControl>
          <FormControl fullWidth style={{ marginTop: 20 }}>
            <Button
              color="primary"
              variant="contained"
              disabled={disableAcceptBtn()}
              onClick={handleAccept}
            >
              Aceptar
            </Button>
          </FormControl>
        </Grid>
        <Grid item xs={12} md={8}>
          <Typography sx={{ marginBottom: 3 }}>Tabla de abonos</Typography>
          {cargandoAbonos && <div>Cargando abonos...</div>}
          {!cargandoAbonos && listaAbonos.length === 0 && (
            <div>No hay abonos</div>
          )}
          {!cargandoAbonos && listaAbonos.length > 0 && (
            <ScrollView scrollBoth maxHeight={430}>
              <Table stickyHeader size="small">
                <TableHead>
                  <TableRow>
                    <TableCell>Fecha</TableCell>
                    <TableCell>Tipo</TableCell>
                    <TableCell>Cantidad</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <CustomList
                    list={listaAbonos}
                    renderItem={(item) => (
                      <TableRow
                        sx={{
                          "&:hover": {
                            background: "rgba(0,0,0,0.075)",
                            cursor: "pointer",
                          },
                        }}
                        onClick={() => handleRowClick(item)}
                      >
                        <TableCell>
                          {Moment(item.fechaAbono).format("llll")}
                        </TableCell>
                        <TableCell>{item.tipo}</TableCell>
                        <TableCell>{Money(item.cantidad)}</TableCell>
                      </TableRow>
                    )}
                  />
                </TableBody>
              </Table>
            </ScrollView>
          )}
        </Grid>
      </Grid>
      <CustomModal
        open={modalConfig.open}
        type={modalConfig.type}
        alertTitle={modalConfig.alertTitle}
        progressTitle={modalConfig.progressTitle}
        showCancelBtn={modalConfig.showCancelBtn}
        showConfirmBtn={modalConfig.showConfirmBtn}
        cancelBtnText={modalConfig.cancelBtnText}
        confirmBtnText={modalConfig.confirmBtnText}
        disableConfirmBtn={disableModalConfirmBtn()}
        onCancel={handleModalCancel}
        onAccept={handleModalAccept}
      >
        <>
          {modalConfig.contentType === "admin-password" && (
            <AdminPasswordField
              password={inputs.password}
              message="Por seguridad, ingresa tu contraseña para poder continuar con el proceso."
              onChange={handleInputChange}
            />
          )}
          {modalConfig.contentType === "eliminar" && (
            <AdminPasswordField
              password={inputs.password}
              message="Por seguridad, ingresa tu contraseña para poder continuar con el proceso."
              onChange={handleInputChange}
            />
          )}
          {modalConfig.contentType === "error" && modalConfig.content}
        </>
      </CustomModal>
    </>
  );
};

const EstadoUsuario = () => {
  const [inputs, setInputs] = useState({
    password: "",
  });
  const [boletosReimpresion, setBoletosReimpresion] = useState([]);
  const [boletoSeleccionado, setBoletoSeleccionado] = useState(null);
  const { selectedUsuario, filtrados } = useSelector((state) => state.usuarios);
  const { modalConfig, setModalConfig } = useModal();
  const toast = useToast();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    cargarBoletos();
  }, []);

  const cargarBoletos = async () => {
    const boletos = await obtenerBoletosReimpresion(selectedUsuario);
    setBoletosReimpresion(boletos);
  };
  const handleOpenModal = (action, boleto = null) => {
    let title = "";
    let message = "";
    if (action === "reset-login-attempts") {
      title = "¿Resetear intentos?";
    }
    if (action === "cambiar-estado") {
      title = selectedUsuario.activo
        ? "¿Desactivar usuario?"
        : "¿Activar usuario?";
      message = selectedUsuario.activo
        ? "Al desactivar a este usuario ya no podrá vender ningún tipo de servicio y se le denegará el acceso al sistema."
        : "Al activar a este usuario podrá vender cualquier tipo de servicio.";
    }
    if (action === "reimprimir") {
      title = "¿Permitir reimpresión?";
    }
    if (action === "eliminar") {
      title = "¿Eliminar usuario?";
    }
    setModalConfig((prevState) => ({
      ...prevState,
      open: true,
      type: "alert",
      alertTitle: title,
      confirmBtnText: "Aceptar",
      contentType: action,
      content: <Typography>{message}</Typography>,
      action: action,
      showCancelBtn: true,
    }));
    setBoletoSeleccionado(boleto);
  };

  const handleInputChange = ({ target }) => {
    setInputs((prevState) => ({
      ...prevState,
      [target.name]: target.value,
    }));
  };

  const handleModalAccept = async () => {
    const actions = {
      "reset-login-attempts": () => resetearIntentos(),
      "cambiar-estado": () => cambiarEstado(),
      reimprimir: () => permitirReimpresion(),
      eliminar: () => eliminar(),
      cerrar: () => handleModalCancel(),
    };
    actions[modalConfig.action]();
  };

  const handleModalCancel = () => {
    setModalConfig((prevState) => ({
      ...prevState,
      open: false,
    }));
    setInputs({ password: "" });
  };

  const resetearIntentos = async () => {
    try {
      setModalConfig((prevState) => ({
        ...prevState,
        open: true,
        type: "progress",
        progressTitle: "Reseteando intentos",
      }));
      const updatedUsuario = await resetLoginAttempts(
        inputs.password,
        selectedUsuario.key
      );
      setModalConfig((prevState) => ({
        ...prevState,
        open: false,
      }));
      setInputs({
        password: "",
      });
      dispatch(setSelectedUsuario(updatedUsuario));
    } catch ({ message }) {
      setModalConfig((prevState) => ({
        ...prevState,
        type: "alert",
        alertTitle: "Mensaje",
        contentType: "error",
        content: <span>{message}</span>,
        action: "cerrar",
        showCancelBtn: false,
        confirmBtnText: "Entendido",
      }));
      setInputs({
        password: "",
      });
    }
  };

  const cambiarEstado = async () => {
    try {
      setModalConfig((prevState) => ({
        ...prevState,
        open: true,
        type: "progress",
        progressTitle: "Cambiando estado",
      }));
      const updatedUsuario = await cambiarEstadoUsuario(
        inputs.password,
        selectedUsuario
      );
      if (updatedUsuario) {
        setModalConfig((prevState) => ({
          ...prevState,
          open: false,
        }));
        setInputs({
          password: "",
        });
        const newUsuarios = filtrados.map((item) => {
          if (item.id === selectedUsuario.id) {
            return { ...item, activo: updatedUsuario.activo };
          }
          return item;
        });
        dispatch(setListaUsuarios(newUsuarios));
        dispatch(cambiarEstadoUsuarioStore(selectedUsuario));
        dispatch(setSelectedUsuario(updatedUsuario));
      }
    } catch ({ message }) {
      setModalConfig((prevState) => ({
        ...prevState,
        type: "alert",
        alertTitle: "Mensaje",
        contentType: "error",
        content: <span>{message}</span>,
        action: "cerrar",
        showCancelBtn: false,
        confirmBtnText: "Entendido",
      }));
      setInputs({
        password: "",
      });
    }
  };

  const permitirReimpresion = async () => {
    try {
      setModalConfig((prevState) => ({
        ...prevState,
        open: true,
        type: "progress",
        progressTitle: "Otorgando permiso",
      }));
      const updated = await cambiarPermisoReimpresion(
        inputs.password,
        selectedUsuario,
        boletoSeleccionado
      );

      if (updated) {
        setModalConfig((prevState) => ({
          ...prevState,
          open: false,
        }));
        setInputs({
          password: "",
        });
        toast.setConfig({ open: true, message: "Permiso otorgado" });
      }
    } catch ({ message }) {
      setModalConfig((prevState) => ({
        ...prevState,
        type: "alert",
        alertTitle: "Mensaje",
        contentType: "error",
        content: <span>{message}</span>,
        action: "cerrar",
        showCancelBtn: false,
        confirmBtnText: "Entendido",
      }));
      setInputs({
        password: "",
      });
    }
  };

  const eliminar = async () => {
    try {
      setModalConfig((prevState) => ({
        ...prevState,
        open: true,
        type: "progress",
        progressTitle: "Eliminando",
      }));
      const usuarioEliminado = await eliminarUsuario(
        inputs.password,
        selectedUsuario
      );
      // SI SE ELIMINO CORRECTAMENTE
      if (usuarioEliminado) {
        dispatch(eliminarUsuarioStore(usuarioEliminado));
        setModalConfig((prevState) => ({
          ...prevState,
          open: false,
        }));
        setInputs({ password: "" });
        navigate("../");
      }
    } catch ({ message }) {
      setModalConfig((prevState) => ({
        ...prevState,
        type: "alert",
        alertTitle: "Mensaje",
        contentType: "error",
        content: <span>{message}</span>,
        action: "cerrar",
        showCancelBtn: false,
        confirmBtnText: "Entendido",
      }));
    }
  };

  const disableModalConfirmBtn = () => {
    if (
      modalConfig.contentType === "reset-login-attempts" &&
      inputs.password.trim() === ""
    ) {
      return true;
    }
    if (
      modalConfig.contentType === "cambiar-estado" &&
      inputs.password.trim() === ""
    ) {
      return true;
    }
    if (
      modalConfig.contentType === "reimprimir" &&
      inputs.password.trim() === ""
    ) {
      return true;
    }
    if (
      modalConfig.contentType === "eliminar" &&
      inputs.password.trim() === ""
    ) {
      return true;
    }
    return false;
  };

  return (
    <>
      <Grid container rowSpacing={1} columnSpacing={1}>
        {selectedUsuario.loginAttempts === 3 && (
          <Grid item xs={12}>
            <Alert
              variant="outlined"
              severity="warning"
              action={
                <Button
                  color="warning"
                  variant="outlined"
                  onClick={() => handleOpenModal("reset-login-attempts")}
                >
                  Resetear intentos
                </Button>
              }
            >
              <AlertTitle>Inicio de sesión</AlertTitle>
              <Typography sx={{ marginBottom: 1.5 }}>
                El usuario ha alcanzado el limite de intentos de inicio de
                sesión por lo tanto se le ha restingido el acceso.
              </Typography>
            </Alert>
          </Grid>
        )}
        {selectedUsuario.loginAttempts < 3 && (
          <Grid item xs={12}>
            <Alert
              variant="outlined"
              severity={selectedUsuario.activo ? "success" : "warning"}
              action={
                <Button onClick={() => handleOpenModal("cambiar-estado")}>
                  {selectedUsuario.activo ? "Desactivar" : "Activar"}
                </Button>
              }
            >
              <AlertTitle>
                {" "}
                {selectedUsuario.activo ? "Activo" : "Inactivo"}
              </AlertTitle>
              {selectedUsuario.activo && (
                <>
                  Este usuario está actualmente (<strong>activo</strong>), por
                  lo tanto puede vender cualquier servicio.
                </>
              )}
              {!selectedUsuario.activo && (
                <>
                  Este usuario está actualmente (<strong>inactivo</strong>), por
                  lo tanto no puede vender ningún servicio.
                </>
              )}
            </Alert>
          </Grid>
        )}
        <Grid item xs={12}>
          <Alert variant="outlined" severity="info">
            <AlertTitle>Reimprimir</AlertTitle>
            <Typography sx={{ marginBottom: 1.5 }}>
              Permite al usuario reimprimir un boleto dando click sobre el
              boleto deseado en la siguiente tabla.
            </Typography>
            <CustomTable
              stickyHeader
              headerData={["Número", "Monto", "Hora"]}
              tableData={boletosReimpresion}
              dataProps={["numeroBoleto", "$:totalApostado", "horaImpresion"]}
              scrollVertical
              maxHeight={250}
              maxWidth="100%"
              onRowClick={(item) => handleOpenModal("reimprimir", item)}
            />
          </Alert>
        </Grid>
        <Grid item xs={12}>
          <Alert
            variant="outlined"
            severity="error"
            action={
              <Button onClick={() => handleOpenModal("eliminar")}>
                Eliminar
              </Button>
            }
          >
            <AlertTitle>Eliminar</AlertTitle>
            <Typography>
              No es recomendable eliminar un usuario ya que esto podría
              representar un conflicto al momento de realizar reportes de venta
              debido a que toda venta realizada por este usuario ya no tendrá
              ninguna relación con ningún vendedor.
            </Typography>
          </Alert>
        </Grid>
      </Grid>
      <CustomModal
        open={modalConfig.open}
        type={modalConfig.type}
        alertTitle={modalConfig.alertTitle}
        progressTitle={modalConfig.progressTitle}
        showCancelBtn={modalConfig.showCancelBtn}
        showConfirmBtn={modalConfig.showConfirmBtn}
        cancelBtnText={modalConfig.cancelBtnText}
        confirmBtnText={modalConfig.confirmBtnText}
        disableConfirmBtn={disableModalConfirmBtn()}
        onCancel={handleModalCancel}
        onAccept={handleModalAccept}
      >
        <>
          {modalConfig.contentType === "admin-password" && (
            <>
              {modalConfig.content}
              <AdminPasswordField
                divider
                password={inputs.password}
                message="Por seguridad, ingresa tu contraseña para poder continuar con el proceso."
                onChange={handleInputChange}
              />
            </>
          )}
          {modalConfig.contentType === "reset-login-attempts" && (
            <>
              <AdminPasswordField
                password={inputs.password}
                message="Por seguridad, ingresa tu contraseña para poder continuar con el proceso."
                onChange={handleInputChange}
              />
            </>
          )}
          {modalConfig.contentType === "cambiar-estado" && (
            <>
              <Typography>{modalConfig.content}</Typography>
              <AdminPasswordField
                divider
                password={inputs.password}
                message="Por seguridad, ingresa tu contraseña para poder continuar con el proceso."
                onChange={handleInputChange}
              />
            </>
          )}
          {modalConfig.contentType === "reimprimir" && (
            <>
              <Typography>
                Permitirás a {selectedUsuario.nomComercial} realizar la
                reimpresión de un boleto.
              </Typography>
              <AdminPasswordField
                divider
                password={inputs.password}
                message="Por seguridad, ingresa tu contraseña para poder continuar con el proceso."
                onChange={handleInputChange}
              />
            </>
          )}
          {modalConfig.contentType === "eliminar" && (
            <>
              <Typography>
                Te sugerimos desactivar al usuario en lugar de eliminarlo, de
                esta manera no podrá vender ningún servicio y se le denegará el
                acceso al sistema.
              </Typography>
              <AdminPasswordField
                divider
                password={inputs.password}
                message="Por seguridad, ingresa tu contraseña para poder continuar con el proceso."
                onChange={handleInputChange}
              />
            </>
          )}
          {modalConfig.contentType === "error" && modalConfig.content}
        </>
      </CustomModal>
      <Toast
        open={toast.config.open}
        message={toast.config.message}
        handleClose={toast.handleClose}
      />
    </>
  );
};
