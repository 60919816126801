import { Box, Toolbar } from "@mui/material";
import { Outlet } from "react-router-dom";
const drawerWidth = 240;

export default function PanelContent() {
  return (
    <Box
      component="main"
      sx={{
        flexGrow: 1,
        p: { xs: 1, md: 3 },
        // width: { sm: `calc(100% - ${drawerWidth}px)` },
        // minHeight: "100vh",
        width: "100%",
        overflow: "auto",
      }}
    >
      <Toolbar />
      {/* DINAMIC CONTENT */}
      <Outlet />
      {/* DEVELOPMENT SIGNAL */}
      {/* <div style={styles.devBar}>Panel de prueba</div> */}
    </Box>
  );
}

const styles = {
  devBar: {
    width: "100%",
    padding: 15,
    textAlign: "center",
    position: "fixed",
    left: 0,
    bottom: 0,
    color: "#fff",
    background: "green",
    opacity: 0.7,
    zIndex: 9999,
  },
};
