import {
  Button,
  Card,
  CardContent,
  CardHeader,
  FormControl,
  IconButton,
  TextField,
} from "@mui/material";
import Tabla from "../../../../components/Tabla";
import PremioPagadoFila from "./PremioPagadoFila";
import { useState, useEffect } from "react";
import { buscarPremioPagado } from "../../../../services/bola";
import {
  setBuscandoPremioPagado,
  setBuscarPremioPagadoActive,
  setResultadosBusquedaPremioPagado,
} from "../../../../features/administracion/administracionSlice";
import { useDispatch, useSelector } from "react-redux";
import { CancelIcon } from "../../../../icons";
import CustomCircularProgress from "../../../../components/CustomCircularProgress";
import CustomSelect from "../../../../components/CustomSelect";
import { getUsers } from "../../../../services/common/";

export default function TablaPremiosPagados() {
  const {
    premiosPagados,
    buscarPremioPagadoActive,
    buscandoPremioPagado,
    resultadosBusquedaPremioPagado,
  } = useSelector((state) => state.administracion);
  const [usuarios, setUsuarios] = useState([]);
  const [filtrados, setFiltrados] = useState(premiosPagados);
  const [userId, setUserId] = useState("");

  useEffect(() => {
    obtenerUsuarios();
  }, []);

  const obtenerUsuarios = async () => {
    try {
      const _usuarios = await getUsers();
      _usuarios.unshift({
        id: "todo",
        nomComercial: "Todo",
      });
      setUsuarios(_usuarios);
      setUserId("todo");
    } catch ({ message }) {
      console.log(message);
    }
  };

  const handleChange = (value) => {
    if (value == "todo") {
      setFiltrados([...premiosPagados]);
      setUserId(value);
      return;
    }
    const user = usuarios.find((user) => user.id === value);
    const newPremiosPagados = [...premiosPagados].filter(
      (item) => item.pagadoPor === user.usuario
    );
    setFiltrados(newPremiosPagados);
    setUserId(value);
  };

  return (
    <Card>
      <CardHeader
        title={
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <Buscador />
            <div style={{ width: 200 }}>
              <CustomSelect
                label="filtrar"
                options={usuarios}
                optionLabel="nomComercial"
                optionValue="id"
                onChange={handleChange}
                value={userId}
              />
            </div>
          </div>
        }
      />
      <CardContent>
        {!buscarPremioPagadoActive && (
          <Tabla
            headData={[
              "ID",
              "FECHA SORTEO",
              "PREMIO",
              "VENDIDO POR",
              "PAGADO POR",
              "FECHA Y HORA PAGO",
              "COMPROBANTE",
            ]}
            data={filtrados}
            renderItem={(item) => <PremioPagadoFila premioPagado={item} />}
          />
        )}
        {buscandoPremioPagado && <CustomCircularProgress />}
        {buscarPremioPagadoActive && !buscandoPremioPagado && (
          <Tabla
            headData={[
              "ID",
              "FECHA SORTEO",
              "PREMIO",
              "VENDIDO POR",
              "PAGADO POR",
              "FECHA Y HORA PAGO",
              "COMPROBANTE",
            ]}
            data={resultadosBusquedaPremioPagado}
            renderItem={(item) => <PremioPagadoFila premioPagado={item} />}
          />
        )}
      </CardContent>
    </Card>
  );
}

function Buscador() {
  const {
    buscarPremioPagadoActive,
    buscandoPremioPagado,
    resultadosBusquedaPremioPagado,
  } = useSelector((state) => state.administracion);
  const [searchValue, setSearchValue] = useState("");
  const dispatch = useDispatch();

  const handleChange = ({ target }) => {
    const { value } = target;
    if (
      !isNaN(value) &&
      ![",", "."].includes(value.toString()) &&
      value.length <= 12
    ) {
      const newValue = value.replace(".", "");
      setSearchValue(newValue.trim());
    }
  };

  const ejecutarBusqueda = async () => {
    try {
      if (searchValue !== "" && searchValue.length === 12) {
        dispatch(setBuscarPremioPagadoActive(true));
        dispatch(setBuscandoPremioPagado(true));
        const boletoID = searchValue.replace(
          /(\d{4})(\d{4})(\d{4})/,
          "$1-$2-$3"
        );
        const resultado = await buscarPremioPagado(boletoID);
        if (resultado === null) {
          dispatch(setResultadosBusquedaPremioPagado([]));
        } else {
          dispatch(setResultadosBusquedaPremioPagado([{ ...resultado }]));
        }
        dispatch(setBuscandoPremioPagado(false));
      }
    } catch ({ message }) {
      alert(message);
      dispatch(setBuscarPremioPagadoActive(false));
      dispatch(setBuscandoPremioPagado(false));
    }
  };

  const handleCerrar = () => {
    dispatch(setBuscarPremioPagadoActive(false));
    setSearchValue("");
    dispatch(setResultadosBusquedaPremioPagado([]));
  };

  return (
    <div
      style={{
        width: "100%",
        maxWidth: 350,
        display: "flex",
        gap: 5,
      }}
    >
      <FormControl fullWidth size="small">
        <TextField
          type="search"
          disabled={false}
          placeholder="ID del ticket"
          size="small"
          value={searchValue}
          onChange={handleChange}
        />
      </FormControl>
      <div style={{ display: "flex" }}>
        <Button
          disabled={
            searchValue === "" ||
            searchValue.length < 12 ||
            searchValue.length > 12 ||
            buscandoPremioPagado
          }
          variant="contained"
          onClick={ejecutarBusqueda}
        >
          Buscar
        </Button>
        {buscarPremioPagadoActive && !buscandoPremioPagado && (
          <IconButton color="error" onClick={handleCerrar}>
            <CancelIcon color="error" />
          </IconButton>
        )}
      </div>
    </div>
  );
}
