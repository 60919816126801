import { useEffect, useState } from "react";
import { getPagosPendientes } from "../../../services/bola";
import { Moment, Money } from "../../../utils";
import { Card, CardContent, CardHeader } from "@mui/material";
import CustomCircularProgress from "../../../components/CustomCircularProgress";
import Tabla from "../../../components/Tabla";
import PagoPendienteFila from "./components/PagoPendienteFila";
import { useDispatch, useSelector } from "react-redux";
import {
  setCargandoPagosPendientes,
  setPagosPendientes,
} from "../../../features/administracion/administracionSlice";

export default function PagosPendientes() {
  const { cargandoPagosPendientes, pagosPendientes } = useSelector(
    (state) => state.administracion
  );
  const [periodo, setPeriodo] = useState({ inicial: "", final: "" });
  const dispatch = useDispatch();

  useEffect(() => {
    cargarPagosPendientes();
  }, []);

  const cargarPagosPendientes = async () => {
    try {
      dispatch(setCargandoPagosPendientes(true));
      const res = await getPagosPendientes();
      dispatch(setPagosPendientes(res.premiosSinPagar));
      setPeriodo(res.periodo);
      dispatch(setCargandoPagosPendientes(false));
    } catch ({ message }) {
      alert(message);
    }
  };

  return (
    <Card>
      <CardHeader
        title={
          cargandoPagosPendientes
            ? "cargando tabla"
            : "Del " +
              Moment(periodo.inicial).format("DD MMMM") +
              " al " +
              Moment(periodo.final).format("DD MMMM")
        }
        subheader={
          <>
            {!cargandoPagosPendientes && (
              <div>
                Total({pagosPendientes.length}):{" "}
                {Money(
                  pagosPendientes.reduce(
                    (acc, el) => acc + parseInt(el.premio),
                    0
                  )
                )}
              </div>
            )}
          </>
        }
      />
      <CardContent>
        {cargandoPagosPendientes && <CustomCircularProgress />}
        {!cargandoPagosPendientes && (
          <Tabla
            withFilter
            headData={["#", "ID", "Fecha sorteo", "Monto", "Usuario"]}
            data={pagosPendientes}
            renderItem={(item, index) => (
              <PagoPendienteFila
                key={index}
                counter={index + 1}
                pagoPendiente={item}
              />
            )}
          />
        )}
      </CardContent>
    </Card>
  );
}
