import React, { useEffect, useState } from "react";
import { Tab, Tabs, Grid, Paper, Box } from "@mui/material";
import Tickets from "./Tickets";
import Transacciones from "./Transacciones";
import RegistroPagos from "./RegistroPagos";
import { useNavigate, useParams } from "react-router-dom";
import SectionTitle from "../../components/SectionTitle";
import PagosPendientes from "./PagosPendientes";
import PremiosPagados from "./PremiosPagados";
import AccountStatusesTab from "./AccountStatusesTab";
import TransaccionesPendientes from "./TransaccionesPendientes";
const adminTabs = {
  index: {
    registroPagos: 0,
    pagosPendientes: 1,
    premiosPagados: 2,
    transacciones: 3,
    tickets: 4,
    transaccionesPendientes: 5,
  },
  name: {
    // 0: "transacciones",
    // 1: "tickets",
    // 2: "registroPagos",
    // 3: "pagosPendientes",
    // 4: "premiosPagados",
    0: "registroPagos",
    1: "pagosPendientes",
    2: "premiosPagados",
    3: "transacciones",
    4: "tickets",
    5: "transaccionesPendientes",
  },
};

export default function Administracion() {
  const { tabName } = useParams();
  const navigate = useNavigate();
  const [value, setValue] = useState(adminTabs.index[tabName] | 0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    navigate("../../../panel/administracion/" + adminTabs.name[newValue]);
  };

  useEffect(() => {
    setValue(adminTabs.index[tabName] | 0);
  }, [tabName]);

  return (
    <>
      <Box>
        <SectionTitle title="Administración" />
      </Box>
      <Box component={Paper}>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="tabs"
          variant="scrollable"
          scrollButtons={"auto"}
        >
          <Tab label="Registrar Depositos" {...a11yProps(0)} />
          <Tab label="Premios Pendientes" {...a11yProps(1)} />
          <Tab label="Premios pagados" {...a11yProps(2)} />
          <Tab label="Transacciones" {...a11yProps(3)} />
          <Tab label="Tickets" {...a11yProps(4)} />
          <Tab label="Transacciones Pendientes" {...a11yProps(5)} />
        </Tabs>
      </Box>
      <TabPanel value={value} index={0}>
        {/* <RegistroPagos /> */}
        <AccountStatusesTab />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <PagosPendientes />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <PremiosPagados />
      </TabPanel>
      <TabPanel value={value} index={3}>
        <Transacciones />
      </TabPanel>
      <TabPanel value={value} index={4}>
        <Tickets />
      </TabPanel>
      <TabPanel value={value} index={5}>
        <TransaccionesPendientes />
      </TabPanel>
    </>
  );
}

function TabPanel(props) {
  const { children, value, index } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      style={{ paddingTop: 20 }}
    >
      {value === index && <>{children}</>}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
