import Database from "../../database";
import { DATABASE_TABLES, DEPOSIT_DAYS } from "../constants";
import {
  addDeposit,
  getDatabaseItem,
  getPeriodFromMonday,
  getServerTimestamp,
  getUserCredit,
  getUsers,
  saveUserWeekInform,
  updateUserCredit,
  getUserWeekInform,
  updateWeekInform,
  suspendUserAccount,
  updateUser,
  getPaidPrizesByRange,
} from "../common";
import { Moment, Utils, uuid } from "../../utils";
import { verifyUser } from "../auth";
const {
  dateToDayLowerCase,
  ticketsSaleComision,
  calculateTransactionsSale,
  calculateTransactionsCommission,
  calculateCommissionChargedToClient,
  sumObjListByProp,
  getPreviousWeekPeriod,
  getLastWeekDateByDayName,
  roundDecimals,
} = Utils;
const ALL_PERIODS = [
  // { start: "2023-07-17", end: "2023-07-23" },// DIDNT WORK
  // { start: "2023-07-24", end: "2023-07-30"},// DIDNT WORK
  // { start: "2023-07-31", end: "2023-08-06" },// DIDNT WORK
  // WORKING
  { start: "2023-08-07", end: "2023-08-13" },
  { start: "2023-08-14", end: "2023-08-20" },
  { start: "2023-08-21", end: "2023-08-27" },
  { start: "2023-08-28", end: "2023-09-03" },
  { start: "2023-09-04", end: "2023-09-10" },
];
// CALCULATES ALL USERS SELLS AND COMMISSIONS AND RETURNS AN ARRAY OF OBJECTS
// WITH ALL THE INFORMATION OF EACH USER
export async function getAccountStatuses() {
  try {
    const users = await getUsers();
    const timestamp = await getServerTimestamp();
    const weekPeriod = { start: "", end: "" };
    // const weekPeriod = { start: "2023-09-11", end: "2023-09-17" };
    weekPeriod.end = getLastWeekDateByDayName(timestamp, "domingo");
    weekPeriod.start = getLastWeekDateByDayName(weekPeriod.end, "lunes");
    const prevWeekPeriod = getPreviousWeekPeriod(weekPeriod);
    const weekReport = await generateWeekReport(weekPeriod);
    const nextPaidPrizes = await getPaidPrizesByRange(
      Moment(weekPeriod.end).add(1, "days").format("YYYY-MM-DD"), // MONDAY
      Moment(weekPeriod.end).add(3, "days").format("YYYY-MM-DD") // WEDNESDAY
    );
    let accountStatuses = [];
    // GET REPORTS FROM EVERY USER
    for (let i = 0; i < users.length; i++) {
      const user = users[i];
      const reference =
        weekPeriod.start + ":" + weekPeriod.end + ":" + user.usuario;
      const weekInform = await createUserWeekInform(weekReport, user);
      const lastInform = await getUserWeekInform(
        prevWeekPeriod.start + ":" + prevWeekPeriod.end + ":" + user.usuario
      );
      const totalUserNextPaidPrizes = nextPaidPrizes
        .filter((item) => item.pagadoPor == user.usuario)
        .reduce((acc, item) => acc + parseFloat(item.premio), 0);
      const userWeekInform = {
        ...weekInform,
        // UPDATE LAST WEEK AMOUNT
        amount:
          lastInform.dueBalance +
          weekInform.amount -
          weekInform.paidPrizesBeforeWeekPaymentLimitDay.total,
        user: user.usuario,
        reference,
      };
      let userInform = {
        id: uuid(),
        user: user.usuario,
        period: userWeekInform.period.start + ":" + userWeekInform.period.end,
        reference,
        totalDeposits: roundDecimals(userWeekInform.deposits.total),
        numDeposits: userWeekInform.deposits.recordsFound,
        amount: roundDecimals(userWeekInform.amount),
        dueBalance:
          roundDecimals(userWeekInform.amount) -
          roundDecimals(userWeekInform.deposits.total),
        created: Moment(timestamp).format("YYYY-MM-DD HH:mm:ss"),
        modified: Moment(timestamp).format("YYYY-MM-DD HH:mm:ss"),
        paymentCompleted: false,
      };
      const totalAfterDeposits =
        userInform.dueBalance - totalUserNextPaidPrizes;
      userInform.paymentCompleted = totalAfterDeposits < 1;
      await saveUserWeekInform(userInform);
      // VERIFY IF LIMIT DAY HAS PASSED AND USER HAS NOT PAID
      // THEN DEACTIVATE ACCOUNT
      if (
        DEPOSIT_DAYS.OUT_OF_LIMIT.includes(
          Moment(timestamp).format("dddd").toLowerCase()
        ) &&
        totalAfterDeposits >= 1
      ) {
        console.log({
          user: user.usuario,
          dueBalance: userInform.dueBalance,
          totalAfterDeposits,
        });
        await suspendUserAccount(user.usuario);
      }
      accountStatuses.push({ ...userWeekInform, user });
    }
    return accountStatuses;
  } catch ({ message }) {
    throw new Error(message);
  }
}
// CALCULATES THE USER SELLS AND COMMISSIONS AND RETURNS AN OBJECT
// WITH ALL THE INFORMATION
// @param period Object = {start: 'YYYY-MM-DD', end: 'YYYY-MM-DD}
// USER
export async function getUserAccountStatus(period, user) {
  try {
    const weekReport = await generateWeekReport(period);
    const weekInform = await createUserWeekInform(weekReport, user);
    const prevPeriod = getPreviousWeekPeriod(period);
    const lastInform = await getUserWeekInform(
      prevPeriod.start + ":" + prevPeriod.end + ":" + user.usuario
    );
    // CREDITO DISPONIBLE
    const creditoDisponible =
      user.limiteVenta -
      weekInform.amount -
      (lastInform.dueBalance -
        weekInform.paidPrizesBeforeWeekPaymentLimitDay.total);
    // console.log({
    //   limiteVenta: user.limiteVenta,
    //   amount: weekInform.amount,
    //   dueBalance: lastInform.dueBalance,
    //   paidPrizesAfterWeekPaymentLimitDay:
    //     weekInform.paidPrizesBeforeWeekPaymentLimitDay.total,
    // });
    return {
      ...weekInform,
      amount:
        lastInform.dueBalance +
        weekInform.amount -
        weekInform.paidPrizesBeforeWeekPaymentLimitDay.total,
      lastInform,
      dueBalance:
        lastInform.dueBalance -
        weekInform.paidPrizesBeforeWeekPaymentLimitDay.total,
      creditoDisponible,
    };
  } catch ({ message }) {
    throw new Error(message);
  }
}
// CREATE USER WEEK INFORM
export async function createUserWeekInform(weekReport, user) {
  try {
    const userWeekReport = getUserWeekReport(weekReport, user);
    const { period } = userWeekReport;
    // SELLS
    const ticketsTotalSale = userWeekReport.tickets.reduce(
      (acc, el) => acc + parseInt(el.totalApostado),
      0
    );
    const totalPaidPrizes = userWeekReport.paidPrizes.reduce(
      (acc, item) => acc + parseFloat(item.premio),
      0
    );
    const paidPrizesBeforeWeekPaymentLimitDay =
      userWeekReport.paidPrizes.filter((item) =>
        ["lunes", "martes", "miercoles"].includes(
          dateToDayLowerCase(item.fechaPago)
        )
      );
    const totalPaidPrizesBeforeWeekPaymentLimitDay =
      paidPrizesBeforeWeekPaymentLimitDay.reduce(
        (acc, item) => acc + parseFloat(item.premio),
        0
      );
    const paidPrizesAfterWeekPaymentLimitDay = userWeekReport.paidPrizes.filter(
      (item) =>
        ["jueves", "viernes", "sabado", "domingo"].includes(
          dateToDayLowerCase(item.fechaPago)
        )
    );
    const totalPaidPrizesAfterWeekPaymentLimitDay =
      paidPrizesAfterWeekPaymentLimitDay.reduce(
        (acc, item) => acc + parseFloat(item.premio),
        0
      );
    const airTime = calculateTransactionsSale(userWeekReport.transactions, "1");
    const packages = calculateTransactionsSale(
      userWeekReport.transactions,
      "2"
    );
    const paidServices = calculateTransactionsSale(
      userWeekReport.transactions,
      "3"
    );
    const giftcards = calculateTransactionsSale(
      userWeekReport.transactions,
      "4"
    );
    const rechargeRecordsFound = airTime.recordsFound + packages.recordsFound;
    const commissionChargedToClient = calculateCommissionChargedToClient(
      userWeekReport.transactions
    );
    const rechargeTotalSale = airTime.total + packages.total;
    // VERIFICAR PERIODO DEL 2023-08-28 AL 2023-09-03
    let commissionChargedToC = commissionChargedToClient;
    if (
      Moment(period.start).isSameOrBefore(Moment("2023-08-28")) &&
      Moment(period.end).isSameOrBefore(Moment("2023-09-03"))
    ) {
      commissionChargedToC = 0;
    }
    const totalSalesSum =
      ticketsTotalSale +
      rechargeTotalSale +
      paidServices.total +
      giftcards.total +
      commissionChargedToC;
    // COMMISSIONS
    const ticketsCommission = ticketsSaleComision(ticketsTotalSale);
    const airTimeCommission = calculateTransactionsCommission(
      userWeekReport.transactions,
      "1"
    );
    const packagesCommission = calculateTransactionsCommission(
      userWeekReport.transactions,
      "2"
    );
    const paidServicesCommission = calculateTransactionsCommission(
      userWeekReport.transactions,
      "3"
    );
    const giftCardsCommission = calculateTransactionsCommission(
      userWeekReport.transactions,
      "4"
    );
    // VERIFICAR PERIODO DEL 2023-08-28 AL 2023-09-03
    let paidPrizes = totalPaidPrizesAfterWeekPaymentLimitDay;
    if (
      Moment(period.start).isSameOrBefore(Moment("2023-08-28")) &&
      Moment(period.end).isSameOrBefore(Moment("2023-09-03"))
    ) {
      paidPrizes = totalPaidPrizes;
    }
    const totalCommissionsSum =
      paidPrizes +
      ticketsCommission +
      airTimeCommission +
      packagesCommission +
      paidServicesCommission +
      giftCardsCommission +
      commissionChargedToClient;
    // FOR TEST ONLY
    const onlyCommissions =
      ticketsCommission +
      airTimeCommission +
      packagesCommission +
      paidServicesCommission +
      giftCardsCommission;
    const totalPayouts = sumObjListByProp(userWeekReport.payouts, "cantidad");
    const totalPaidPrizesPayouts = 0;
    const totalRefunds = sumObjListByProp(userWeekReport.refunds, "cantidad");
    const totalAdjustments = sumObjListByProp(
      userWeekReport.adjustments,
      "cantidad"
    );
    const totalPrizesAndCommissionsWithoutPayouts =
      totalCommissionsSum -
      totalPayouts -
      totalPaidPrizesPayouts +
      (totalRefunds + totalAdjustments);
    const amount = totalSalesSum - totalPrizesAndCommissionsWithoutPayouts;
    // USER WEEK INFORM
    return {
      period: weekReport.period,
      user: user.usuario,
      tickets: {
        total: ticketsTotalSale,
        recordsFound: userWeekReport.tickets.length,
        commission: ticketsCommission,
      },
      canceledTickets: {
        total: sumObjListByProp(userWeekReport.canceledTickets, "reembolso"),
        recordsFound: userWeekReport.canceledTickets.length,
      },
      paidPrizesBeforeWeekPaymentLimitDay: {
        total: totalPaidPrizesBeforeWeekPaymentLimitDay,
        recordsFound: paidPrizesBeforeWeekPaymentLimitDay.length,
        commission: 0,
      },
      paidPrizesAfterWeekPaymentLimitDay: {
        total: totalPaidPrizesAfterWeekPaymentLimitDay,
        recordsFound: paidPrizesAfterWeekPaymentLimitDay.length,
        commission: 0,
      },
      recharges: {
        total: rechargeTotalSale,
        recordsFound: rechargeRecordsFound,
        commission: airTimeCommission + packagesCommission,
        chargeToClientForService: rechargeRecordsFound * 2,
      },
      paidServices: {
        total: paidServices.total,
        recordsFound: paidServices.recordsFound,
        commission: paidServicesCommission,
      },
      giftCards: {
        total: giftcards.total,
        recordsFound: giftcards.recordsFound,
        commission: giftCardsCommission,
      },
      payouts: {
        total: totalPayouts,
        recordsFound: userWeekReport.payouts.length,
      },
      // WILL NOT LONGER WORK
      paidPrizesPayouts: {
        total: totalPaidPrizesPayouts,
        recordsFound: userWeekReport.paidPrizesPayouts.length,
      },
      refunds: {
        total: totalRefunds,
        recordsFound: userWeekReport.refunds.length,
      },
      adjustments: {
        total: totalAdjustments,
        recordsFound: userWeekReport.adjustments.length,
      },
      deposits: {
        total: sumObjListByProp(userWeekReport.deposits, "pago"),
        recordsFound: userWeekReport.deposits.length,
        list: userWeekReport.deposits,
      },
      totalSalesSum,
      totalCommissionsSum,
      commissionChargedToClient,
      totalPrizesAndCommissionsWithoutPayouts,
      amount,
      cash: 0,
      // FOR TEST ONLY
      onlyCommissions,
    };
  } catch ({ message }) {
    throw new Error(message);
  }
}
// GENERATE A WEEK REPORT FROM A GIVEN PERIOD
// IF NOT PERIOD IS GIVEN IT THEN CREATES A PERIOD
// FROM MONDAY TO NOW
// @param object = {start: 'YYYY-MM-DD', end: 'YYYY-MM-DD'}
export async function generateWeekReport(period = null) {
  try {
    let _period = period; // { start: "", end: "" };
    // IF PERIOD IS NULL THEN GENERATE A PERIOD FROM MONDAY TO NOW
    if (period === null) {
      const serverTimestamp = await getServerTimestamp();
      _period = getPeriodFromMonday(serverTimestamp);
    }
    const tickets = await Database.getItemsInRange(
      DATABASE_TABLES.TICKETS,
      "fechaExp",
      _period.start,
      _period.end
    );
    const transactions = await Database.getItemsInRange(
      DATABASE_TABLES.TRANSACTIONS,
      "_fecha",
      _period.start,
      _period.end,
      (txn) => txn.Status === "Exitosa" || txn.Status === "PROCESSING"
    );
    const paidPrizes = await Database.getItemsInRange(
      DATABASE_TABLES.PAID_PRIZES,
      "fechaPago",
      _period.start,
      _period.end
    );
    const payouts = await Database.getItemsInRange(
      DATABASE_TABLES.PAYOUTS,
      "fechaAbono",
      _period.start,
      _period.end
    );
    const deposits = await Database.getItemsByProp(
      DATABASE_TABLES.DEPOSITS,
      "periodo",
      _period.start + ":" + _period.end
    );
    const canceledTickets = await Database.getItemsInRange(
      DATABASE_TABLES.CANCELED_TICKETS,
      "fechaCancelacion",
      _period.start,
      _period.end
    );
    const pastDueBalances = await Database.getItems(
      DATABASE_TABLES.PAST_DUE_BALANCES
    );

    return {
      // CANCELADOS
      canceledTickets,
      // DEPOSITOS
      deposits,
      // PAGOS DE PREMIOS
      paidPrizes,
      // ABONOS
      payouts,
      // SALDOS VENCIDOS
      pastDueBalances,
      // PERIODOS
      period: _period,
      // TICKETS
      tickets,
      // TRANSACCIONES
      transactions,
    };
  } catch ({ message }) {
    throw new Error(message);
  }
}
// RETURNS A USER ACOUNT STATUS FROM A GIVEN WEEK REPORT
export function getUserWeekReport(weekReport, user) {
  const {
    canceledTickets,
    deposits,
    paidPrizes,
    payouts,
    pastDueBalances,
    period,
    tickets,
    transactions,
  } = weekReport;
  // console.log(
  //   transactions.filter(
  //     (item) => item._usuario === user.usuario && item.Telefono == "4776502439"
  //   )
  // );
  return {
    adjustments: payouts.filter(
      (item) => item.agenciaId === user.id && item.tipo === "ajuste"
    ),
    canceledTickets: canceledTickets.filter(
      (item) => item.agencia === user.usuario
    ),
    deposits: deposits.filter((item) => item.usuario === user.usuario),
    paidPrizes: paidPrizes.filter((item) => item.pagadoPor === user.usuario),
    payouts: payouts.filter(
      (item) => item.agenciaId === user.id && item.tipo === "abono"
    ),
    paidPrizesPayouts: payouts.filter(
      (item) => item.agenciaId === user.id && item.tipo === "pago-premios"
    ),
    refunds: payouts.filter(
      (item) => item.agenciaId === user.id && item.tipo === "reembolso"
    ),
    pastDueBalances: pastDueBalances.filter(
      (item) => item.usuario === user.usuario
    ),
    period,
    tickets: tickets.filter((item) => item.numeroAgencia === user.usuario),
    transactions: transactions.filter((item) => item._usuario === user.usuario),
  };
}
// SAVE DEPOSIT
export async function saveDeposit({
  accountStatus,
  depositAmount,
  period,
  user,
  password,
}) {
  try {
    // VERIFY ADMIN USER
    await verifyUser(password);
    const timestamp = await getServerTimestamp();
    const userCredit = await getUserCredit(user.usuario);
    const newDeposit = {
      id: uuid(),
      fecha: Moment(timestamp).format("YYYY-MM-DD"),
      hora: Moment(timestamp).format("HH:mm:ss"),
      pago: parseFloat(depositAmount),
      periodo: period.start + ":" + period.end,
      referencia: period.start + "#" + period.end + "#" + user.usuario,
      usuario: user.usuario,
      importe: accountStatus.amount,
      metodoPago: "",
      resto: 0,
      saldoVencido:
        parseFloat(accountStatus.amount) - parseFloat(depositAmount),
      tipoResto: "pago-completo",
      totalApagar: accountStatus.amount,
    };
    let userCreditAndDepositAmountSum =
      userCredit.saldo + parseFloat(depositAmount);
    if (accountStatus.deposits.length === 0) {
      userCreditAndDepositAmountSum += parseFloat(
        accountStatus.onlyCommissions
      );
    }
    const newBalance =
      userCreditAndDepositAmountSum > user.limiteVenta
        ? parseFloat(user.limiteVenta)
        : userCreditAndDepositAmountSum;
    // ADD NEW DEPOSIT TO DATABASE
    await addDeposit(newDeposit);
    // UPDATE USER CREDIT
    await updateUserCredit(userCredit.key, newBalance);
    // GET NEXT WEEK PAID PRIZES FROM MONDAY TO WEDNESDAY
    const nextPaidPrizes = await getPaidPrizesByRange(
      Moment(period.end).add(1, "days").format("YYYY-MM-DD"), // MONDAY
      Moment(period.end).add(3, "days").format("YYYY-MM-DD") // WEDNESDAY
    );
    // FILTER BY USER AND CALCULATE DE SUM OF PRIZES
    const totalUserNextPaidPrizes = nextPaidPrizes
      .filter((item) => item.pagadoPor == user.usuario)
      .reduce((acc, item) => acc + parseFloat(item.premio), 0);
    // VERIFY IF LIMIT DAY HAS PASSED AND USER HAS NOT PAID THEN DEACTIVATE ACCOUNT
    const totalAfterDeposits =
      accountStatus.amount -
      accountStatus.deposits.total -
      totalUserNextPaidPrizes -
      parseFloat(depositAmount);
    if (
      DEPOSIT_DAYS.OUT_OF_LIMIT.includes(
        Moment(timestamp).format("dddd").toLowerCase()
      ) &&
      totalAfterDeposits >= 1
    ) {
      console.log("saveDeposit", " 529");
      await suspendUserAccount(user.usuario);
    }
    // UPDATE INFORM
    const paymentCompleted = totalAfterDeposits < 1;
    await updateWeekInform(
      accountStatus.reference,
      depositAmount,
      paymentCompleted
    );
    // UPDATE USER
    if (
      DEPOSIT_DAYS.OUT_OF_LIMIT.includes(
        Moment(timestamp).format("dddd").toLowerCase()
      )
    ) {
      console.log(paymentCompleted, " line 546");
      await updateUser(user.key, { activo: paymentCompleted });
    }
    // RETURN RECENT ADDED DEPOSIT
    return await getDatabaseItem("DEPOSITS", "id", newDeposit.id);
  } catch ({ message }) {
    throw new Error(message);
  }
}
// GET DEPOSITS VOUCHERS
export async function getVouchers() {
  try {
    const vouchers = await Database.getItems(DATABASE_TABLES.VOUCHERS);

    return Utils.sortArrayByDateTime(vouchers, "fecha", "desc");
  } catch ({ message }) {
    throw new Error(message);
  }
}
// CHANGE VOUCHER CHECK STATE
export async function changeVoucherCheckState(voucherKey) {
  try {
    await Database.update(DATABASE_TABLES.VOUCHERS, voucherKey, {
      visto: true,
    });
  } catch ({ message }) {
    throw new Error(message);
  }
}
