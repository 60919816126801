import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { useEffect, useRef, useState } from "react";
import {
  changeVoucherCheckState,
  getVouchers,
} from "../../../../services/reports";
import Lightbox from "yet-another-react-lightbox";
import { Captions } from "yet-another-react-lightbox/plugins";
import "yet-another-react-lightbox/styles.css";
import "yet-another-react-lightbox/plugins/captions.css";

export default function Comprobantes() {
  const [fetchingData, setFetchingData] = useState(true);
  const [vouchers, setVouchers] = useState([]);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      setFetchingData(true);
      const data = await getVouchers();
      //   console.log(data);
      setVouchers(data);
      setFetchingData(false);
    } catch ({ message }) {
      console.log(message);
    }
  };

  return (
    <Box sx={{ maxWidth: "100%", overflow: "auto" }}>
      <Typography sx={{ marginTop: 3, marginBottom: 2 }} component={"h2"}>
        Comprobantes
      </Typography>
      <Table stickyHeader size="small">
        <TableHead>
          <TableRow>
            {["USUARIO", "COMPROBANTE", "FECHA SUBIDA"].map((colName) => (
              <TableCell key={colName}>{colName}</TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {vouchers.map((item, index) => (
            <ComprobanteRow
              key={item.capturaUrl}
              comprobante={item}
              index={index}
              images={vouchers.map((image) => ({
                src: image.capturaUrl,
                title: `${image.numeroUsuario} - ${image.fecha} `,
              }))}
            />
          ))}
        </TableBody>
      </Table>
    </Box>
  );
}

function ComprobanteRow({ comprobante, index, images }) {
  const [open, setOpen] = useState(false);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const captionsRef = useRef(null);
  const [voucher, setVoucher] = useState({ ...comprobante });

  const handleOnclick = () => {
    setCurrentImageIndex(index);
    setOpen(true);
    changeVoucherState();
  };

  const changeVoucherState = async () => {
    if (!voucher.visto) {
      setVoucher((prevState) => ({ ...prevState, visto: true }));
      await changeVoucherCheckState(comprobante.key);
    }
  };

  return (
    <>
      <TableRow sx={{ background: voucher.visto ? undefined : "orange" }}>
        <TableCell>{voucher.numeroUsuario}</TableCell>
        <TableCell>
          <a
            href={voucher.capturaUrl}
            style={{ color: "white", textDecoration: "underline" }}
            onClick={(e) => {
              e.preventDefault();
              handleOnclick();
            }}
          >
            archivo de imagen
          </a>
        </TableCell>
        <TableCell>{voucher.fecha}</TableCell>
      </TableRow>
      <Lightbox
        index={currentImageIndex}
        plugins={[Captions]}
        captions={{ ref: captionsRef }}
        on={{
          click: () => {
            (captionsRef.current?.visible
              ? captionsRef.current?.hide
              : captionsRef.current?.show)?.();
          },
        }}
        open={open}
        close={() => setOpen(false)}
        slides={images}
      />
    </>
  );
}
