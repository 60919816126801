import { TableCell, TableRow } from "@mui/material";
import { Money } from "../../../../utils";

export default function PremioPagadoFila({ premioPagado }) {
  const isVerifiable = premioPagado.premio >= 999;
  // capturaUrl
  const handleClick = () => {
    if (premioPagado.premio < 999) return;
    // console.log(premioPagado);
  };

  const buildText = () => {
    if (!isVerifiable) return "";
    if (isVerifiable && premioPagado.capturaUrl === undefined)
      return "sin capturar";
    return "archivo de imagen";
  };

  return (
    <TableRow
      onClick={handleClick}
      sx={{
        // cursor: isVerifiable && "pointer",
        backgroundColor: isVerifiable && "orange",
      }}
    >
      <CustomCell
        text={premioPagado.numeroBoleto}
        isVerifiable={isVerifiable}
      />
      <CustomCell text={premioPagado.fechaSorteo} isVerifiable={isVerifiable} />
      <CustomCell
        text={Money(premioPagado.premio)}
        isVerifiable={isVerifiable}
      />
      <CustomCell text={premioPagado.vendidoPor} isVerifiable={isVerifiable} />
      <CustomCell text={premioPagado.pagadoPor} isVerifiable={isVerifiable} />
      <CustomCell
        text={premioPagado.fechaPago + " " + premioPagado.horaPago}
        isVerifiable={isVerifiable}
      />
      <CustomCell
        text={buildText()}
        url={premioPagado.capturaUrl}
        isVerifiable={isVerifiable}
      />
    </TableRow>
  );
}

function CustomCell({ text, isVerifiable, url }) {
  return (
    <TableCell sx={{ color: isVerifiable && "#000" }}>
      {url === undefined && text}
      {url !== undefined && url.length > 0 && (
        <a href={url} target="_blank">
          {text}
        </a>
      )}
      {url !== undefined && url.length === 0 && text}
    </TableCell>
  );
}
